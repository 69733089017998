<template>
  <BaseModal
    id="interventionConfigModal"
    class="interventionEditorConfig pageModal"
    :headerText="$t('interventionTranslation.changeConfig')"
    :leftButtonText="$t('generalTranslation.save')"
    :disableButtons="disableConfigBool"
    :disableLeftButton="!isOwner"
    @close-modal="closeModal"
  >
    <template v-slot:body>
      <div v-if="!loadingConfig" :id="idInterventionType" class="wrapper diaryInt">
        <h3>{{ $t("interventionTranslation.publishInterventionCheck") }}</h3>
        <section class="checkboxSection notBold configElement">
          <p>{{ $t("interventionTranslation.publishInterventionCompleteCheckTooltip", { locales: "" }) }}</p>
          <div v-for="lang in languages" :key="lang.code">
            <label class="container">
              {{ lang.code }}
              <input v-model="lang.getsPublished" type="checkbox" :id="'checkLanguageCheckbox' + lang.code">
              <span class="checkmark"></span>
            </label>
          </div>
        </section>

        <InterventionCheck
          :intervention="interventionToCheck"
          :languages="publishedLocales"
          @close-notification="closeNotification"
          @quit-check="closeModal(false)"
        />
      </div>

      <div class="wrapper diaryInt">
        <div v-if="!loadingConfig" :id="idInterventionType" class="col secL">
          <p v-if="isCollaborator" class="wrapper">
            {{ 'interventionTranslation.editableForOwner' | translate }}
          </p>
          {{ interventionTypeText }}
        </div>
        <div v-if="$enabledFeatures.includes('jitai') && hasConditionalLesson" class="col secR">
          <button
            type="button"
            id="buttonCheckConfig"
            class="buttonForm"
            :disabled="!isOwner || disableConfigBool"
            @click="checkConfigConditions"
          >
            {{ 'interventionTranslation.checkConfig' | translate }}
          </button>
        </div>
      </div>

      <ul v-if="!loadingConfig" class="configClass wrapper">
        <div class="wrapper">
          <div class="col sec50">
            <h3>
              {{ 'interventionTranslation.unlockDiariesIntervention' | translate }}<br>
              {{ 'interventionTranslation.unlockDiariesInterventionExplanation' | translate }}
            </h3>
            <section class="wrapper diaryInt">
              <section class="checkboxSection notBold col sectionLeft4">
              </section>
              <section class="col configElement">
                <ul v-if="diariesArr.length > 0" id="diariesIntervention">
                  <li v-for="(diaryId, diaryIndex) in unlockDiaryList.int" :id="'diariesIntervention' + diaryId" :key="diaryId">
                    {{ getNameOfDiary(diaryId) }}
                    <i
                      v-if="isOwner"
                      class="fa fa-trash"
                      :id="'buttonRemoveDiaryInt' + diaryId"
                      v-tooltip="$t('lessonTranslation.removeElement')"
                      @click="changeConfig({ attribute: 'unlock-diary-int-delete', index: diaryIndex })"
                    ></i>
                  </li>
                </ul>
                <section v-if="unlockDiaryList.int.length === 0" id="diariesInterventionNone">
                  {{ 'interventionTranslation.unlockDiariesEmpty' | translate }}
                </section>
                <br>
                <label>
                  {{ 'interventionTranslation.unlockDiariesAdd' | translate }}
                </label>
                <select
                  v-if="getSelectableDiariesIntervention.length > 0"
                  v-model="selectedDiaryInt"
                  id="inputAddDiaryIntervention"
                  class="addLanguageSelect buttonForm"
                  :disabled="!isOwner"
                >
                  <option value="" id="inputAddDiaryInterventionNone" disabled selected>
                    {{ 'interventionTranslation.chooseDiary' | translate }}
                  </option>
                  <option
                    v-for="diary in getSelectableDiariesIntervention"
                    :value="Number(diary.id)"
                    :id="'inputAddDiaryIntervention' + diary.id"
                    :key="diary.id"
                  >
                    {{ diary.attributes.name }}
                  </option>
                </select>
                <p v-else id="addDiaryInterventionNone">
                  {{noDiariesText}}
                </p>
                <button
                  type="button"
                  v-if="getSelectableDiariesIntervention.length > 0"
                  id="buttonAddDiaryIntervention"
                  :disabled="!isOwner || selectedDiaryInt === ''"
                  class="buttonMiddle2"
                  @click="changeConfig({ attribute: 'unlock-diary-int-set' })"
                >
                  {{ 'interventionTranslation.add' | translate }}
                </button>
              </section>
            </section>
          </div>
          <div v-if="$enabledFeatures.includes('jitai')" class="col sec50">
            <h3>
              {{ $t("interventionTranslation.unlockLessons") }}
              <VTooltip class="far" theme="info-tooltip">
                <i class="far fa-question-circle"></i>
                <template #popper>
                  {{ $t("interventionTranslation.unlockLessonsTooltipHeader") }}
                  <dl>
                    <dt>
                      <u>{{ $t("interventionTranslation.unlockLessonsMultiple") }}</u>
                    </dt>
                    <dd>{{ $t("interventionTranslation.unlockLessonsTooltipMultipleManual") }}</dd>
                    <dt>
                      <u>{{ $t("interventionTranslation.unlockLessonsOne") }}</u>
                    </dt>
                    <dd>{{ $t("interventionTranslation.unlockLessonsTooltipSingleManual") }}</dd>
                    <dt>
                      <u>{{ $t("interventionTranslation.unlockMultipleRandom") }}</u>
                    </dt>
                    <dd>{{ $t("interventionTranslation.unlockLessonsTooltipMultipleRandom") }}</dd>
                    <dt>
                      <u>{{ $t("interventionTranslation.unlockSingleRandom") }}</u>
                    </dt>
                    <dd>{{ $t("interventionTranslation.unlockLessonsTooltipSingleRandom") }}</dd>
                  </dl>
                </template>
              </VTooltip>
            </h3>
            <section class="wrapper diaryInt">
              <section class="checkboxSection notBold col sectionLeft4">
              </section>
              <section class="col configElement">
                <select
                  v-model="unlockMultipleLessons"
                  id="inputUnlockMultiple"
                  class="addLanguageSelect buttonForm"
                  :disabled="!isOwner || !hasConditionalLesson"
                >
                  <option value="" id="inputUnlockMultipleNone" disabled selected>
                    {{ $t("generalTranslation.choose") }}
                  </option>
                  <option value="multiple_manual" id="inputUnlockMultipleTrue" key="multiple">
                    {{ $t("interventionTranslation.unlockLessonsMultiple") }}
                  </option>
                  <option value="single_manual" id="inputUnlockMultipleFalse" key="one">
                    {{ $t("interventionTranslation.unlockLessonsOne") }}
                  </option>
                  <option value="multiple_random" id="inputUnlockMultipleRandomTrue" key="r1">
                    {{ $t("interventionTranslation.unlockMultipleRandom") }}
                  </option>
                  <option value="single_random" id="inputUnlockMultipleRandomFalse" key="r0">
                    {{ $t("interventionTranslation.unlockSingleRandom") }}
                  </option>
                </select>
              </section>
            </section>
          </div>
        </div>

        <!-- in custom order -->
        <InterventionEditorConfigElement
          :showIncluded="true"
          :configJSON="configJSON"
          :lessonsDiaries="lessonsDiaries"
          :customOrder="customOrder"
          :unlockDiaryList="unlockDiaryList"
          :daysOrWeeks="daysOrWeeks"
          :interventionType="interventionDetails.intervention_type"
          :errorIndexes="errorIndexes"
          :lessonElementsLabels="lessonElementsLabels"
          @change-config="changeConfig"
          @open-condition-editor="openConditionEditor"
        ></InterventionEditorConfigElement>

        <!-- not in custom order -->
        <InterventionEditorConfigElement
          :showIncluded="false"
          :configJSON="configJSON"
          :lessonsDiaries="lessonsDiaries"
          :customOrder="customOrder"
          :unlockDiaryList="unlockDiaryList"
          :daysOrWeeks="daysOrWeeks"
          :interventionType="interventionDetails.intervention_type"
          :errorIndexes="errorIndexes"
          :lessonElementsLabels="lessonElementsLabels"
          @change-config="changeConfig"
          @open-condition-editor="openConditionEditor"
        ></InterventionEditorConfigElement>
      </ul>
      <i class="fa fa-spinner fa-spin fa-2x loadData" v-else></i>
    </template>
  </BaseModal>
</template>

<script>
import Vue from "vue";
import { mapGetters,mapMutations } from "vuex";
import httpHelper from "../mixins/httpHelper";
import questionConditionHelper from "../mixins/questionConditionHelper";
import BaseModal from "./BaseModal.vue";
import InterventionEditorConfigElement from "./InterventionEditorConfigElement.vue";
import InterventionCheck from "./InterventionCheck.vue";
import { DependencyGraph } from "../utils/DependencyGraph";

export default {
  name: "InterventionEditorConfig",

  components: {
    BaseModal,
    InterventionCheck,
    InterventionEditorConfigElement,
  },

  mixins: [httpHelper, questionConditionHelper],

  props: {
    allLessonsArray: {
      required: true,
      type: Array,
    },

    interventionDetails: {
      required: true,
      type: Object,
    },
  },

  data: function() {
    const languages = this.interventionDetails.locales.map(l => ({ code: l, getsPublished: false }));
    return {
      loadingConfig: true,
      disableConfigBool: false,
      diariesArr: [],
      configJSON: [],
      oldData: {},
      customOrder: [],
      unlockDiaryList: {},
      daysOrWeeks: [],
      errorIndexes: [],
      lessonElementsLabels: {},
      selectedDiaryInt: "",
      unlockMultipleLessons: "multiple_manual",
      languages,
    };
  },

  computed: {
    ...mapGetters([
      "getMyRoleForStudy",
      "getNotificationText",
      "getConditionEditorData",
      "getQuestionLabelList",
    ]),

    noUnlockedLessonInConfig: function() {
      const prefix = Vue.i18n.translate("interventionTranslation.errorConfigNoUnlockedLesson");
      const always = Vue.i18n.translate("interventionTranslation.always");
      const atDate = Vue.i18n.translate("interventionTranslation.atDate");
      const or = Vue.i18n.translate("interventionTranslation.or");
      if (this.interventionDetails.intervention_type === "accompanied") {
        const manually = Vue.i18n.translate("interventionTranslation.manually");
        return `${prefix}: "${always}", "${manually}" ${or} "${atDate}".`;
      } else {
        return `${prefix}: "${always}" ${or} "${atDate}".`;
      }
    },

    interventionId: function() {
      return Number(this.$route.params.interventionId);
    },

    publishedLocales: function() {
      return this.languages.filter(l => l.getsPublished).map(l => l.code);
    },

    interventionToCheck: function() {
      return {
        name: this.interventionDetails.name,
        id: this.interventionId,
      };
    },

    isOwner: function() {
      return this.getMyRoleForStudy === "Owner";
    },

    isCollaborator: function() {
      return this.getMyRoleForStudy === "Collaborator";
    },

    getSelectableDiariesIntervention: function() {
      return this.diariesArr.filter(
        (diary) => !this.unlockDiaryList.int.includes(Number(diary.id)) || !this.unlockDiaryList.int.includes(diary.id),
      );
    },

    idInterventionType: function() {
      return this.interventionDetails.intervention_type === "accompanied" ? "interventionGuided" : "interventionNotGuided";
    },

    interventionTypeText: function() {
      if (this.interventionDetails.intervention_type === "accompanied") {
        return Vue.i18n.translate("interventionTranslation.interventionGuidedSelected");
      } else {
        return Vue.i18n.translate("interventionTranslation.interventionUnguidedSelected");
      }
    },

    allSelectedDiaries: function() {
      const diaries = [];
      for (const diary in this.unlockDiaryList) {
        for (const entry in this.unlockDiaryList[diary]) {
          if (!diaries.includes(Number(this.unlockDiaryList[diary][entry]))) {
            diaries.push(Number(this.unlockDiaryList[diary][entry]));
          }
        }
      }
      return diaries;
    },

    configChanged: function() {
      return (
        JSON.stringify(this.configJSON) != JSON.stringify(this.oldData.configJSON) ||
        JSON.stringify(this.customOrder) != JSON.stringify(this.oldData.customOrder) ||
        JSON.stringify(this.unlockDiaryList) != JSON.stringify(this.oldData.unlockDiaryList) ||
        this.unlockMultipleLessons != this.oldData.unlockMultipleLessons
      );
    },

    lessonsDiaries: function() {
      const lessonsAndDiaries = this.allLessonsArray.concat(this.diariesArr);
      const result = [];
      for (const elem in lessonsAndDiaries) {
        const lessonOrDiary = lessonsAndDiaries[elem];
        if (lessonOrDiary.type === "diary") {
          lessonOrDiary.attributes.diaryId = lessonOrDiary.id;
          lessonOrDiary.attributes.id = lessonOrDiary.attributes.questionnaire_id;
        } else {
          lessonOrDiary.attributes.id = lessonOrDiary.id;
        }
        result.push(lessonOrDiary.attributes);
      }
      return result;
    },

    noDiariesText: function(){
      return !this.unlockDiaryList.int || this.unlockDiaryList.int.length === 0
        ? Vue.i18n.translate("interventionTranslation.noDiariesInStudy")
        : Vue.i18n.translate("interventionTranslation.noMoreDiariesInStudy");
    },

    hasConditionalLesson: function() {
      for (const config in this.configJSON) {
        if (this.configJSON[config].unlock_type === "conditional") {
          return true;
        }
      }
      return false;
    },
  },

  watch: {
    getNotificationText(newVal) {
      if (
        (newVal != "" && newVal.type === "error") ||
        (newVal != "" && newVal.type === "success" &&
            newVal.text === Vue.i18n.translate("interventionTranslation.updateInterventionConfigSuccess"))
      ) {
        this.disableConfigBool = false;
      }
    },
  },

  created() {
    document.body.style.overflow = "hidden";
    if (this.getConditionEditorData === "" || !this.$enabledFeatures.includes("jitai")) {
      this.getNewDiaries(this.interventionDetails.study_id, 0, "");
      this.SET_CONDITIONEDITORDATA("");
    } else {
      this.configJSON = this.getConditionEditorData.configJSON;
      this.customOrder = this.getConditionEditorData.customOrder;
      this.unlockDiaryList = this.getConditionEditorData.unlockDiaryList;
      this.oldData = this.getConditionEditorData.oldData;
      this.diariesArr = this.getConditionEditorData.diariesArr;
      this.daysOrWeeks = this.getConditionEditorData.daysOrWeeks;
      this.lessonElementsLabels = this.getConditionEditorData.lessonElementsLabels;
      this.unlockMultipleLessons = this.getConditionEditorData.unlockMultipleLessons;

      //new condition has to be set for lesson
      const lessonId = this.getConditionEditorData.pos;
      const index = this.configJSON.findIndex(elem => Number(elem.id) === Number(lessonId));
      if (index !== -1) {
        this.changeConfig({
          attribute: "config-attribute",
          index: index,
          attributeName: "conditionJSON",
          value: this.getConditionEditorData.condition,
          conditionIndex: this.getConditionEditorData.conditionIndex,
        });
      }
      this.SET_CONDITIONEDITORDATA("");
      this.loadingConfig = false;
    }
  },

  beforeDestroy(){
    document.body.style.overflow = "visible";
  },

  methods: {
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
      "SET_QUESTIONLABELLIST",
      "SET_CONDITIONEDITORDATA",
    ]),

    getDependencyGraphResultText: function(result) {
      const invalidLesson = this.allLessonsArray.find(l => l.id == result.id)?.attributes;
      const general = Vue.i18n.translate(
        "interventionTranslation.errorConfigLockedLesson",
        { position: invalidLesson?.position, lesson: invalidLesson?.name },
      );
      const specific = Vue.i18n.translate(result.text);
      return `${general} ${specific}`;
    },

    getNewDiaries: function(studyId, page, filterTerm) {
      this.requestDiariesOfStudy(studyId, page, filterTerm, -1, false)
        .then((response) => {
          this.diariesArr = response.list;
          this.checkAndUpdateConfig();
        })
        .catch((error) => {
          this.handleErrors(error, () => this.getNewDiaries(studyId, page, filterTerm), "");
        });
    },

    getUnlockConditionalType(interventionDetails) {
      const unlockMultiple = interventionDetails.unlock_multiple_conditionals;
      const jitais = interventionDetails.jitais;
      if (unlockMultiple == undefined && jitais == undefined) {
        return "multiple_manual";
      } else if (unlockMultiple == undefined) {
        if (jitais.unlock_multiple_conditionals == undefined || jitais.unlock_multiple_conditionals) {
          return jitais.unlock_conditionals_type === "random" ? "multiple_random" : "multiple_manual";
        } else {
          return jitais.unlock_conditionals_type === "random" ? "single_random" : "single_manual";
        }
      } else {
        if (unlockMultiple) {
          return jitais?.unlock_conditionals_type === "random" ? "multiple_random" : "multiple_manual";
        } else {
          return jitais?.unlock_conditionals_type === "random" ? "single_random" : "single_manual";
        }
      }
    },

    checkAndUpdateConfig: function() {
      const unsortedConfig = this.interventionDetails.default_configuration
        ? JSON.parse(JSON.stringify(this.interventionDetails.default_configuration.questionnaire_configuration))
        : [];
      const sortedConfig = [];
      this.customOrder = this.interventionDetails.default_configuration
        ? JSON.parse(JSON.stringify(this.interventionDetails.default_configuration.custom_order))
        : [];
      this.unlockMultipleLessons = this.getUnlockConditionalType(this.interventionDetails);

      //add entries for lessons not included in config
      for (const l in this.allLessonsArray) {
        const lessonId = this.allLessonsArray[l].id;
        const config = unsortedConfig.find(elem => elem.id == lessonId);
        if (config === undefined) {
          sortedConfig.push(
            {
              id: lessonId,
              unlock_type: "after_previous",
              unlock_days_after_start: null,
              feedback_required: false,
            },
          );
        } else {
          sortedConfig.push(config);
        }
      }

      const unlockList = {};
      for (const l in this.allLessonsArray) {
        const lesson = this.allLessonsArray[l];
        let unlock = lesson.attributes.unlock_diaries;
        if (!unlock) {
          unlock = [];
        }
        unlockList[lesson.id] = unlock.sort(this.sortNumber);
      }

      for (const lessonConfig in sortedConfig) {
        const index = this.allLessonsArray.findIndex(elem => elem.id === sortedConfig[lessonConfig].id);

        if (index === 0 && sortedConfig[lessonConfig].unlock_type === "after_previous") {
          sortedConfig[lessonConfig].unlock_type = "always";
        }
        if (sortedConfig[lessonConfig].unlock_type === "at_date") {
          if (sortedConfig[lessonConfig].unlock_days_after_start % 7 === 0) {
            sortedConfig[lessonConfig].unlock_days_after_start = sortedConfig[lessonConfig].unlock_days_after_start / 7;
            this.daysOrWeeks[lessonConfig] = false;
          } else {
            this.daysOrWeeks[lessonConfig] = true;
          }
        } else {
          let conditionEmpty = false;
          if (sortedConfig[lessonConfig].unlock_type === "conditional") {
            if (this.$enabledFeatures.includes("jitai")) {
              //change from single condition to array
              if (!Array.isArray(sortedConfig[lessonConfig].condition)) {
                const singleCondition = JSON.parse(JSON.stringify(sortedConfig[lessonConfig].condition));
                sortedConfig[lessonConfig].condition = [singleCondition];
              }
              const newConditions = [];
              //check if lessons/diaries used in condition are part of this study (may not be the case after copy intervention)
              for (const c in sortedConfig[lessonConfig].condition) {
                //set text instead of labellist
                if (!sortedConfig[lessonConfig].condition[c].text) {
                  const conditionLabels = sortedConfig[lessonConfig].condition[c].labels;
                  if (conditionLabels) {
                    sortedConfig[lessonConfig].condition[c].text = Vue.i18n.translate("interventionTranslation.conditionLesson") + " " +
                      this.treeToString(sortedConfig[lessonConfig].condition[c].ifBlock, conditionLabels, false);
                  }
                }

                //set type of condition (true if condition for question, false if for lesson finished)
                if (sortedConfig[lessonConfig].condition[c].ifBlock === true) {
                  sortedConfig[lessonConfig].condition[c].type = false;
                } else {
                  sortedConfig[lessonConfig].condition[c].type = true;
                }

                let id = sortedConfig[lessonConfig].condition[c].diary_id;
                if (id === null) { //lesson
                  id = sortedConfig[lessonConfig].condition[c].questionnaire_id;
                  if (this.allLessonsArray.findIndex(elem => Number(elem.id) === Number(id)) != -1) {
                    newConditions.push(sortedConfig[lessonConfig].condition[c]);
                  }
                } else if (this.diariesArr.findIndex(elem => Number(elem.id) === Number(id)) != -1) { //diary
                  newConditions.push(sortedConfig[lessonConfig].condition[c]);
                }
              }

              //check if condition now empty
              if (newConditions.length > 0) {
                sortedConfig[lessonConfig].condition = newConditions;
              } else {
                sortedConfig[lessonConfig].condition = null;
                conditionEmpty = true;
              }

              //remove unlock diaries for conditional lessons (which could have been set before changing the unlock type to conditional)
              unlockList[sortedConfig[lessonConfig].id] = [];

              if (sortedConfig[lessonConfig].bonus_lesson === undefined) {
                sortedConfig[lessonConfig].bonus_lesson = true;
              }
            } else {
              sortedConfig[lessonConfig].unlock_type = "always";
              delete sortedConfig[lessonConfig]["condition"];
            }
          }

          if ((sortedConfig[lessonConfig].unlock_type === "manually" && this.interventionDetails.intervention_type === "unaccompanied") || conditionEmpty) {
            sortedConfig[lessonConfig].unlock_type = (index === 0) ? "always" : "after_previous";
          }

          //default values if unlockType changed to atdate
          sortedConfig[lessonConfig].unlock_days_after_start = 1;
          this.daysOrWeeks[lessonConfig] = true;
        }

        if (this.interventionDetails.intervention_type === "unaccompanied") {
          sortedConfig[lessonConfig].feedback_required = false;
        }
      }

      this.configJSON = sortedConfig;

      unlockList.int = this.interventionDetails.unlock_diaries ? JSON.parse(JSON.stringify(this.interventionDetails.unlock_diaries)) : [];
      this.unlockDiaryList = unlockList;
      this.oldData = {
        configJSON: JSON.parse(JSON.stringify(this.configJSON)),
        customOrder: JSON.parse(JSON.stringify(this.customOrder)),
        unlockDiaryList: JSON.parse(JSON.stringify(this.unlockDiaryList)),
        unlockMultipleLessons: this.unlockMultipleLessons,
      };
      this.loadingConfig = false;
    },

    changeConfig: function(obj) {
      switch (obj.attribute) {
        case "config-attribute":
          this.setConfigAttributeOfLesson(obj);
          break;
        case "days-or-weeks":
          this.daysOrWeeks[obj.index] = (obj.value == true || obj.value === "true" ? true : false);
          break;
        case "unlock-type-all":
          for (const lesson in this.configJSON) {
            this.configJSON[lesson].unlock_type = obj.unlockType;
            if (obj.unlockType === "at_date") {
              this.configJSON[lesson].unlock_days_after_start = obj.days;
            }
          }
          if (obj.unlockType === "at_date") {
            this.daysOrWeeks.fill(obj.daysOrWeeks);
          }
          break;
        case "feedback-all":
          for (const lesson in this.configJSON) {
            this.configJSON[lesson].feedback_required = obj.value;
          }
          break;
        case "unlock-diary-set":
          this.unlockDiaryList[obj.lessonId] = JSON.parse(JSON.stringify(obj.value.sort(this.sortNumber)));
          break;
        case "unlock-diary-delete":
          this.unlockDiaryList[obj.lessonId].splice(obj.diaryIndex, 1);
          break;
        case "unlock-diary-int-set":
          if (this.selectedDiaryInt != "") {
            const newList = this.unlockDiaryList.int;
            newList.push(this.selectedDiaryInt);
            this.unlockDiaryList.int = JSON.parse(JSON.stringify(newList.sort(this.sortNumber)));
            this.selectedDiaryInt = "";
          }
          break;
        case "unlock-diary-int-delete":
          this.unlockDiaryList.int.splice(obj.index, 1);
          break;
        case "custom-order":
          if (obj.value) { //add
            let notAdded = true;
            for (let c = 0, l = 0; c < this.customOrder.length && l < this.configJSON.length; l++) {
              const lessonId = this.configJSON[l].id;
              if (lessonId == this.customOrder[c]) {
                c++;
              } else if (lessonId == obj.lessonId) {
                this.customOrder.splice(c, 0, obj.lessonId);
                notAdded = false;
                break;
              }
            }
            if (notAdded) {
              this.customOrder.push(obj.lessonId);
            }
          } else { //delete
            const index = this.customOrder.indexOf(obj.lessonId);
            if (index !== -1) {
              this.customOrder.splice(index, 1);
            }
          }
          break;
        case "custom-order-add-all": {
          const newCustomOrder = [];
          for (const lesson in this.allLessonsArray) {
            newCustomOrder.push(Number(this.allLessonsArray[lesson].id));
          }
          this.customOrder = newCustomOrder;
          break;
        }
        case "custom-order-delete-all":
          this.customOrder = [];
          break;
        default:
      }
    },

    setConfigAttributeOfLesson: function(obj) {
      if (!obj.attributeName.includes("condition") && !obj.attributeName.includes("Condition")) {
        this.configJSON[obj.index][obj.attributeName] = obj.value;

        if (
          obj.attributeName === "unlock_type" &&
          !this.configJSON[obj.index].condition &&
          this.$enabledFeatures.includes("jitai")
        ) {
          this.configJSON[obj.index].condition = [{
            questionnaire_id: "",
            diary_id: null,
            ifBlock: null,
            type: true,
            text: "",
            description: "",
          }];
        }

        if (obj.attributeName === "unlock_type" && obj.value === "conditional") {
          if (this.$enabledFeatures.includes("jitai")) {
            //remove unlock diaries
            this.unlockDiaryList[this.configJSON[obj.index].id] = [];
          } else {
            this.configJSON[obj.index][obj.attributeName] = "always";
          }
        }
      } else {
        if (!this.configJSON[obj.index].condition) {
          this.configJSON[obj.index].condition = [{
            questionnaire_id: "",
            diary_id: null,
            ifBlock: null,
            type: true,
            text: "",
            description: "",
          }];
        }

        switch (obj.attributeName) {
          case "conditionId": {
            const index = this.diariesArr.findIndex(diary => Number(diary.attributes.questionnaire_id) === Number(obj.value));
            const oldCondition = this.configJSON[obj.index].condition[obj.conditionIndex];
            const newCondition = {
              questionnaire_id: obj.value,
              diary_id: index !== -1 ? this.diariesArr[index].id : null,
              ifBlock: !oldCondition.type ? true : null,
              type: oldCondition.type,
              text: "",
              description: oldCondition.description,
            };
            this.configJSON[obj.index].condition[obj.conditionIndex] = newCondition;
            this.configJSON = JSON.parse(JSON.stringify(this.configJSON)); //reinit nested (to update questionnaire_id)
            break;
          }
          case "conditionJSON": {
            this.configJSON[obj.index].condition[obj.conditionIndex].ifBlock = obj.value;
            const text = Vue.i18n.translate("interventionTranslation.conditionLesson") + " " + this.treeToString(obj.value, this.getQuestionLabelList, false);
            this.configJSON[obj.index].condition[obj.conditionIndex].text = text;
            break;
          }
          case "conditionDesc":
            this.configJSON[obj.index].condition[obj.conditionIndex].description = obj.value;
            break;
          case "addCondition":
            this.configJSON[obj.index].condition.push({
              questionnaire_id: "",
              diary_id: null,
              ifBlock: null,
              type: true,
              text: "",
              description: "",
            });
            break;
          case "removeCondition":
            this.configJSON[obj.index].condition.splice(obj.conditionIndex, 1);
            this.configJSON = JSON.parse(JSON.stringify(this.configJSON)); //reinit nested
            break;
          case "conditionType":
            this.configJSON[obj.index].condition[obj.conditionIndex].type = obj.value === true || obj.value === "true";
            if (obj.value === true || obj.value === "true") { //question
              this.configJSON[obj.index].condition[obj.conditionIndex].ifBlock = null;
            } else {
              this.configJSON[obj.index].condition[obj.conditionIndex].ifBlock = true;
            }
            this.configJSON[obj.index].condition[obj.conditionIndex].text = "";
            break;
          default:
        }
      }
    },

    getNameOfDiary: function(diary) {
      const index = this.diariesArr.findIndex(elem => Number(elem.id) === Number(diary));
      return index !== -1 ? this.diariesArr[index].attributes.name : "";
    },

    sortNumber: function(a, b) {
      return a - b;
    },

    checkConfig: function() {
      let hasErrors = false;
      let currentHasErrors = false;
      let hasNoUnlockedLesson = true;
      let hasConditionalLesson = false;
      this.errorIndexes = {};
      const accompaniedIntervention = this.interventionDetails.intervention_type === "accompanied";
      const allowsConditions = this.$enabledFeatures.includes("jitai");
      const linkedQuestionnaires = new Set();
      for (const lesson in this.configJSON) {
        const lessonConfig = this.configJSON[lesson];
        if (lessonConfig.unlock_type === "conditional") {
          if (!allowsConditions) {
            lessonConfig.unlock_type = "always";
          } else {
            const indexes = [];
            for (const c in lessonConfig.condition) {
              const lessonCondition = lessonConfig.condition[c];
              if (lessonCondition.type) { //is question (does not have to be checked for lesson finished)
                //if questionnaire id set check if valid -> has to be lesson id or (diary lesson id & this has to be in unlock diaries of any lesson / intervention)
                //if this is not the case, set empty
                if (lessonCondition.questionnaire_id) {
                  const lessonIndex = this.allLessonsArray.findIndex(elem => Number(elem.id) === Number(lessonCondition.questionnaire_id));
                  //index != -1 -> is lesson -> valid id
                  //index === 1 -> is diary -> check if in allSelectedDiaries
                  if (lessonIndex === -1) {
                    const diaryIndex = this.diariesArr.findIndex(elem => Number(elem.attributes.id) === Number(lessonCondition.questionnaire_id));
                    if (diaryIndex === -1) {
                      //diary does not exist
                      lessonCondition.questionnaire_id = "";
                      lessonCondition.ifBlock = null;
                      lessonCondition.type = true;
                    } else {
                      //check if diary selected
                      const diaryId = Number(this.diariesArr[diaryIndex].id);
                      if (!this.allSelectedDiaries.includes(diaryId)) {
                        lessonCondition.questionnaire_id = "";
                        lessonCondition.ifBlock = null;
                        lessonCondition.type = true;
                      }
                    }
                  }
                }
              }
              if (!lessonCondition || !lessonCondition.questionnaire_id || !lessonCondition.ifBlock) {
                indexes.push(Number(c));
                currentHasErrors = true;
              } else {
                if (!lessonCondition.description) {
                  currentHasErrors = true;
                }
                if (accompaniedIntervention && lessonCondition.diary_id == null) {
                  linkedQuestionnaires.add(Number(lessonCondition.questionnaire_id));
                }
              }
            }

            if (currentHasErrors) {
              this.errorIndexes[lessonConfig.id] = { conditions: indexes };
              currentHasErrors = false;
              hasErrors = true;
            }
          }
        }

        if (lessonConfig.unlock_type === "conditional" || lessonConfig.unlock_type === "after_previous") {
          hasConditionalLesson = true;
        } else if (hasNoUnlockedLesson && this.customOrder.some(id => id == lessonConfig.id)) {
          hasNoUnlockedLesson = false;
        }
      }
      let hasRequiredConditionalLesson = false;
      for (const id of linkedQuestionnaires) {
        const config = this.configJSON.find((cfg) => Number(cfg.id) === id);
        if (config !== undefined && config.feedback_required) {
          if (this.errorIndexes[config.id]) {
            this.errorIndexes[config.id].feedback = true;
          } else {
            this.errorIndexes[config.id] = { feedback: true };
          }
          hasRequiredConditionalLesson = true;
        }
      }

      if (hasErrors) {
        let text = Vue.i18n.translate("interventionTranslation.errorConditionsIncomplete");
        if (hasRequiredConditionalLesson) {
          text += " " + Vue.i18n.translate("interventionTranslation.errorRequiredConditions");
        }
        this.SET_NOTIFICATIONTEXT({ type: "error", text: text });
        return;
      }
      if (hasRequiredConditionalLesson) {
        this.SET_NOTIFICATIONTEXT({
          type: "error",
          text: Vue.i18n.translate("interventionTranslation.errorRequiredConditions"),
        });
        return;
      }
      if (hasNoUnlockedLesson) {
        this.SET_NOTIFICATIONTEXT({ type: "error", text: this.noUnlockedLessonInConfig });
        return;
      }
      if (hasConditionalLesson) {
        const result = DependencyGraph.check(this.configJSON, this.customOrder, this.unlockDiaryList);
        if (result !== undefined) {
          const text = this.getDependencyGraphResultText(result);
          this.SET_NOTIFICATIONTEXT({ type: "error", text: text });
          return;
        }
      }
      this.saveConfig();
    },

    saveConfig: function() {
      this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.updateInterventionConfigLoad") });
      this.disableConfigBool = true;

      for (const lesson in this.configJSON) {
        const lessonConfig = this.configJSON[lesson];
        if (lessonConfig.unlock_type === "at_date") {
          if (!this.daysOrWeeks[lesson]) {
            lessonConfig.unlock_days_after_start = lessonConfig.unlock_days_after_start * 7;
          }
        } else {
          lessonConfig.unlock_days_after_start = null;
        }

        const allowsConditions = this.$enabledFeatures.includes("jitai");
        if (allowsConditions && lessonConfig.unlock_type == "conditional") {
          //remove unlock diaries for conditional lessons (which could have been set before changing the unlock type to conditional)
          this.unlockDiaryList[lessonConfig.id] = [];
          //set condition to true if condition for lesson finished, remove type
          for (const c in lessonConfig.condition) {
            const lessonCondition = lessonConfig.condition[c];
            if (!lessonCondition.type) {
              lessonCondition.ifBlock = true;
            }
            delete lessonCondition["type"];
          }
        } else if (allowsConditions) {
          lessonConfig.condition = null;
        } else {
          delete lessonConfig["condition"];
        }
      }

      const unlockDiariesChanged = JSON.stringify(this.unlockDiaryList) != JSON.stringify(this.oldData.unlockDiaryList);
      const newUnlockData = [];
      //remove ids of deleted lessons from custom_order
      const customOrder = this.customOrder;
      const newCustomOrder = [];
      const allLessonIds = [];
      for (const lesson in this.allLessonsArray) {
        const lessonId = this.allLessonsArray[lesson].id;
        if (customOrder.some(id => id == lessonId)) {
          newCustomOrder.push(Number(lessonId));
        }
        allLessonIds.push(Number(lessonId));
        if (unlockDiariesChanged && JSON.stringify(this.unlockDiaryList[lessonId]) != JSON.stringify(this.oldData.unlockDiaryList[lessonId])) {
          newUnlockData.push({
            questionnaire_id: lessonId,
            unlock_diaries: this.unlockDiaryList[lessonId],
          });
        }
      }

      //remove config of deleted questionnaires and duplicate entries from questionnaire_configuration
      const newQuestionnaireConfig = [];
      const alreadyAdded = [];
      for (const lesson in this.configJSON) {
        const lessonConfig = this.configJSON[lesson];
        const lessonId = Number(lessonConfig.id);
        if (allLessonIds.includes(lessonId) && !alreadyAdded.includes(lessonId)) {
          newQuestionnaireConfig.push(lessonConfig);
          alreadyAdded.push(lessonId);
        }
      }

      let unlockMultipleConditionals;
      let unlockConditionalsType;
      switch (this.unlockMultipleLessons) {
        case "single_manual":
          unlockMultipleConditionals = 0;
          unlockConditionalsType = "manual";
          break;
        case "single_random":
          unlockMultipleConditionals = 0;
          unlockConditionalsType = "random";
          break;
        case "multiple_random":
          unlockMultipleConditionals = 1;
          unlockConditionalsType = "random";
          break;
        default:
          unlockMultipleConditionals = 1;
          unlockConditionalsType = "manual";
          break;
      }
      const json = {
        data: {
          type: "interventions",
          id: this.interventionId,
          attributes: {
            default_configuration: {
              questionnaire_configuration: newQuestionnaireConfig,
              custom_order: newCustomOrder,
            },
            unlock_diaries: this.unlockDiaryList.int,
            unlock_multiple_conditionals: unlockMultipleConditionals,
            jitais: {
              unlock_multiple_conditionals: unlockMultipleConditionals,
              unlock_conditionals_type: unlockConditionalsType,
            },
          },
        },
      };

      if (newUnlockData.length > 0) {
        const unlockJson = {
          data: {
            type: "questionnaires",
            attributes: { unlock_data: newUnlockData },
          },
        };
        this.updateUnlockDiaries(unlockJson, json);
      } else {
        this.$emit("close-modal", { done: true, json: json });
      }
    },

    updateUnlockDiaries: function(unlockJson, json) {
      this.updateUnlockDiariesForLessonsRequest(unlockJson)
        .then(() => {
          this.$emit("close-modal", { done: true, json: json });
        })
        .catch((error) => {
          this.handleErrors(error, () => this.updateUnlockDiaries(unlockJson, json), "");
        });
    },

    closeModal: function(done) {
      if (done) {
        this.checkConfig();
      } else if (!this.disableConfigBool) {
        if (
          this.isOwner &&
          this.configChanged &&
          (this.getNotificationText === "" ||
            (this.getNotificationText.text !== Vue.i18n.translate("interventionTranslation.unsavedChangesConfigLeave") &&
              this.getNotificationText.text !== Vue.i18n.translate("interventionTranslation.unsavedChangesConfigLeaveInterventionEditor")))
        ) {
          this.SET_NOTIFICATIONTEXT({
            type: "error",
            text: Vue.i18n.translate("interventionTranslation.unsavedChangesConfigLeave"),
          });
        } else {
          this.$emit("close-modal", { done: false });
        }
      }
    },

    openConditionEditor: function(obj) {
      if (!obj.open) {
        this.SET_NOTIFICATIONTEXT({
          type: "error",
          text: Vue.i18n.translate("interventionTranslation.errorSelectLessonDiary"),
        });
        return;
      }
      this.requestLessonElements(obj.conditionId, false, false)
        .then((response) => {
          const labellist = this.getLabelList(response).list;
          let labellistEmpty = true;
          for (const label in labellist) {
            if (labellist[label] != 0 && labellist[label] != undefined) {
              labellistEmpty = false;
              break
            }
          }
          if (labellistEmpty) {
            this.SET_NOTIFICATIONTEXT({
              type: "error",
              text: Vue.i18n.translate("interventionTranslation.errorNoQuestions"),
            });
          } else {
            this.SET_QUESTIONLABELLIST(labellist);
            const data = {
              pos: obj.lessonId, //condition config for this lesson
              condition: obj.conditionJSON, //condition
              conditionId: obj.conditionId, //diary or lesson for which condition is defined (question labels from these elements)
              conditionIndex: obj.conditionIndex, //index of condition for this lesson (lesson may have multiple conditions)
              configJSON: this.configJSON,
              customOrder: this.customOrder,
              unlockMultipleLessons: this.unlockMultipleLessons,
              unlockDiaryList: this.unlockDiaryList,
              oldData: this.oldData, //old values of configJSON, customOrder, unlockDiaryList, unlockMultipleLessons
              diariesArr: this.diariesArr,
              daysOrWeeks: this.daysOrWeeks,
              lessonElementsLabels: this.lessonElementsLabels,
              configChanged: this.configChanged,
            };
            this.$emit("open-condition-editor", data);
          }
        })
        .catch((error) => {
          this.handleErrors(error, () => this.openConditionEditor(obj), "studyDetails");
        });
    },

    checkConfigConditions: async function() {
      this.SET_NOTIFICATIONTEXT({
        type: "load",
        text: Vue.i18n.translate("interventionTranslation.checkConfigLoad"),
      });
      this.loadingConfig = true;
      this.disableConfigBool = true;
      const lessonIdsWithLinkedQuestion = [];
      const allConditions = [];
      let hasConditionalLesson = false;
      let hasNoUnlockedLesson = true;
      for (const entry in this.configJSON) {
        const lessonConfig = this.configJSON[entry];
        if (lessonConfig.unlock_type === "conditional") {
          const conditions = lessonConfig.condition;
          for (const c in conditions) {
            const condition = conditions[c];
            // if it is a condition for lesson finished, it will just be ignored; these conditions are always valid & do not use elements
            if (condition.ifBlock != true) {
              allConditions.push(condition);
              const id = Number(condition.questionnaire_id);
              if (!lessonIdsWithLinkedQuestion.includes(id)) {
                lessonIdsWithLinkedQuestion.push(id);
              }
            }
          }
          hasConditionalLesson = true;
        } else if (lessonConfig.unlock_type === "after_previous") {
          hasConditionalLesson = true;
        } else if (hasNoUnlockedLesson && this.customOrder.some(id => id == lessonConfig.id)) {
          hasNoUnlockedLesson = false;
        }
      }

      if (hasNoUnlockedLesson) {
        this.loadingConfig = false;
        this.disableConfigBool = false;
        this.SET_NOTIFICATIONTEXT({ type: "error", text: this.noUnlockedLessonInConfig });
        return;
      }
      if (hasConditionalLesson) {
        const result = DependencyGraph.check(this.configJSON, this.customOrder, this.unlockDiaryList);
        if (result !== undefined) {
          const text = this.getDependencyGraphResultText(result);
          this.loadingConfig = false;
          this.disableConfigBool = false;
          this.SET_NOTIFICATIONTEXT({ type: "error", text: text });
          return;
        }
      }

      const lessonElementsLabels = {};
      for (const i in lessonIdsWithLinkedQuestion) {
        const lessonId = lessonIdsWithLinkedQuestion[i];
        const elements = await this.exportLessonElementsRequest(lessonId);
        const elementProps = this.jsonToProperties(elements, false);
        const labelList = this.getLabelList(elementProps).list;
        lessonElementsLabels[lessonId] = { elements: elementProps, labelList: labelList };
      }
      this.lessonElementsLabels = lessonElementsLabels;
      //check if conditions valid (for notification message only)
      const self = this;
      const checkedConditions = allConditions.map(condition => self.treeToString(condition.ifBlock, lessonElementsLabels[condition.questionnaire_id].labelList, false).includes("..."));
      if (checkedConditions.includes(true)) {
        this.loadingConfig = false;
        this.disableConfigBool = false;
        this.SET_NOTIFICATIONTEXT({
          type: "error",
          text: Vue.i18n.translate("interventionTranslation.checkConfigInvalid"),
        });
        return;
      }

      this.loadingConfig = false;
      this.disableConfigBool = false;
      this.SET_NOTIFICATIONTEXT({
        type: "success",
        text: Vue.i18n.translate("interventionTranslation.checkConfigValid"),
      });
    },

    closeNotification: function() {
      this.$emit("close-notification");
    },
  }
}
</script>
