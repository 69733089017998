<template>
  <div class="interventionEditorConfigElement" :id="'config' + idForComp">
    <section class="wrapper">
      <h3 class="col sec50 headlineConfig">
        {{headlineText}}
      </h3>
      <section v-if="showIncluded" class="buttonFormRightLesson">
        <button type="button" id="buttonAddAllCustomOrder" class="buttonForm" :disabled="!isOwner" @click="$emit('change-config', { attribute: 'custom-order-add-all'})">
          {{ 'interventionTranslation.customOrderAddAll' | translate }}
        </button>
        <button type="button" id="buttonRemoveAllCustomOrder" :disabled="!isOwner" @click="$emit('change-config', { attribute: 'custom-order-delete-all'})">
          {{ 'interventionTranslation.customOrderRemoveAll' | translate }}
        </button>
      </section>
    </section>

    <div v-if="!inCustomOrderHasLessons" :id="'noLessons' + idForComp" class="noneInConfig">
      {{ 'generalTranslation.none' | translate }}
    </div>

    <div v-for="(lesson, lessonIndex) in lessonsDiaries" :id="'entryLessonConfig' + lesson.id" :key="lesson.id">
      <div v-if="!lesson.diaryId && ((showIncluded && customOrder.includes(Number(lesson.id))) || (!showIncluded && !customOrder.includes(Number(lesson.id))))">
        <section class="checkboxSection notBold col sectionLeft4">
          <label class="container">
            <input type="checkbox" :value="Number(lesson.id)" :checked="customOrder.includes(Number(lesson.id))" :id="'inputSelectLesson' + lesson.id"
              :disabled="!isOwner" @change="$emit('change-config', { attribute: 'custom-order', lessonId: Number(lesson.id), value: $event.target.checked})">
            <span class="checkmark"></span>
          </label>
        </section>
        <li class="interventionElement col configElement" :class="{conditionalAfterPrevious: conditionalAfterPrevious.includes(Number(lesson.id))}">
          <h3>
            {{lesson.position}} {{lesson.name}}
          </h3>
          <section class="wrapper">
            <section class="col sec50 dateSection">
              <section class="wrapper">
                <section class="col sec50Middle">
                  <label>
                    {{ 'interventionTranslation.unlocktype' | translate }} *
                  </label>
                </section>
                <section class="col sec50Middle">
                  <button type="button" v-if="lessonIndex === useForAllButtonsIndex" :id="'buttonSetUnlockAll' + lesson.id" :disabled="!isOwner" @click="setUnlockForAll(lesson.id)">
                    {{ 'interventionTranslation.setUnlocktypeForAll' | translate }}
                  </button>
                  <label v-if="getConfigAttributeOfLesson(lesson.id, 'unlock_type') === 'conditional'">
                    {{ 'interventionTranslation.showLessonAs' | translate }} *
                  </label>
                </section>
              </section>
              <section class="wrapper">
                <section class="col sec50">
                  <select :value="getConfigAttributeOfLesson(lesson.id, 'unlock_type')" :id="'inputUnlockType' + lesson.id" class="addLanguageSelect buttonForm"
                      :disabled="!isOwner" @change="setConfigAttributeOfLesson(lesson.id, $event.target.value, 'unlock_type', -1)">
                    <option value="" :id="'inputUnlockType' + lesson.id + 'None'" disabled>
                      {{ 'interventionTranslation.chooseUnlocktype' | translate }}
                    </option>
                    <option v-for="data in unlockTypeList.filter(data => !((data.value === 'after_previous' && lesson.position === 1) || interventionType === 'unaccompanied' && data.value === 'manually'))"
                        :value="data.value" :key="data.value" :id="'inputUnlockType' + lesson.id + data.value">
                      {{data.value === 'after_previous' && conditionalAfterPrevious.includes(Number(lesson.id)) ? conditionalAfterPreviousText : data.text}}
                    </option>
                  </select>
                </section>
                <section class="col sec50">
                  <select v-if="getConfigAttributeOfLesson(lesson.id, 'unlock_type') === 'conditional'" :value="getConfigAttributeOfLesson(lesson.id, 'bonus_lesson')" :disabled="!isOwner"
                      :id="'inputBonusLesson' + lesson.id" @change="setConfigAttributeOfLesson(lesson.id, $event.target.value, 'bonus_lesson', -1)">
                    <option value="" :id="'inputBonusLesson' + lesson.id + 'None'" disabled>
                      {{ 'generalTranslation.select' | translate }}
                    </option>
                    <option :value="false" :id="'inputBonusLesson' + lesson.id + 'False'" :key="'lessonType' + showIncluded">
                      {{ 'interventionTranslation.normalLesson' | translate }}
                    </option>
                    <option :value="true" :id="'inputBonusLesson' + lesson.id + 'True'" :key="'lessonType' + showIncluded">
                      {{ 'interventionTranslation.bonusLesson' | translate }}
                    </option>
                  </select>
                </section>
              </section>

              <section v-if="getConfigAttributeOfLesson(lesson.id, 'unlock_type') === 'at_date'">
                <input type="number" :value="getConfigAttributeOfLesson(lesson.id, 'unlock_days_after_start')" min="1" :id="'inputUnlockDate' + lesson.id"
                  :disabled="!isOwner" @input="setConfigAttributeOfLesson(lesson.id, Number($event.target.value), 'unlock_days_after_start', -1)">
                <select :value="getDaysOrWeeksOfLesson(lesson.id)" :id="'inputUnlockDateType' + lesson.id" :disabled="!isOwner" @change="setDaysOrWeeksOfLesson(lesson.id, $event.target.value)">
                  <option value="" :id="'inputUnlockDateType' + lesson.id + 'None'" disabled>
                    {{ 'interventionTranslation.chooseDateType' | translate }}
                  </option>
                  <option :value="true" :id="'inputUnlockDateType' + lesson.id + 'Days'">
                    {{ 'interventionTranslation.dateTypeDays' | translate }}
                  </option>
                  <option :value="false" :id="'inputUnlockDateType' + lesson.id + 'Weeks'">
                    {{ 'interventionTranslation.dateTypeWeeks' | translate }}
                  </option>
                </select>
              </section>

              <section v-if="getConfigAttributeOfLesson(lesson.id, 'unlock_type') === 'conditional'" class="conditionalSec"> <!-- todo ids -->
                {{ 'interventionTranslation.conditionExplanation' | translate }}

                <div
                  v-for="(condition, conditionIndex) in getConfigAttributeOfLesson(lesson.id, 'conditions')"
                  :key="conditionIndex + condition.questionnaire_id"
                >
                  <section class="conditionElement">
                    <section :class="{col: isOwner, secAnswer80: isOwner}">
                      <section class="wrapper conditionBorder">
                        <section class="col sec50">
                          <label
                            :class="{
                              errorMessage: condition.questionnaire_id === '' && errorIndexes[lesson.id]?.conditions?.includes(conditionIndex),
                            }">
                            {{ 'interventionTranslation.selectLessonOrDiary' | translate }} *
                          </label>
                          <select
                            :value="condition.questionnaire_id"
                            :disabled="!isOwner"
                            @change="setConfigAttributeOfLesson(lesson.id, $event.target.value, 'conditionId', conditionIndex)"
                          >
                            <option value="" disabled>
                              {{ 'generalTranslation.select' | translate }}
                            </option>
                            <option
                              v-for="lessonDiary in getSelectableLessonsDiaries(lesson.id, conditionIndex)"
                              :key="lessonDiary.id" :value="lessonDiary.id"
                            >
                              {{ lessonDiary.name }}
                            </option>
                          </select>
                          <label
                            :class="{
                              errorMessage: (!condition.description || condition.description === '') && errorIndexes[lesson.id]?.conditions !== undefined,
                            }">
                            {{ 'interventionTranslation.descriptionCondition' | translate }} *
                          </label>
                          <input
                            type="text"
                            :value="condition.description"
                            :placeholder="$t('interventionTranslation.descriptionConditionShort')"
                            :disabled="!isOwner"
                            @input="setConfigAttributeOfLesson(lesson.id, $event.target.value, 'conditionDesc', conditionIndex)"
                          >
                        </section>
                        <section class="col sec50">
                          <label
                            :class="{
                              errorMessage: errorIndexes[lesson.id]?.conditions?.includes(conditionIndex),
                            }">
                            {{ 'conditionEditorTranslation.condition' | translate }} *
                          </label>

                          <select
                            :value="condition.type"
                            :disabled="!isOwner || condition.questionnaire_id == ''"
                            @change="setConfigAttributeOfLesson(lesson.id, $event.target.value, 'conditionType', conditionIndex)"
                          >
                            <option value="" disabled>
                              {{ 'generalTranslation.select' | translate }}
                            </option>
                            <option key="question" :value="true">
                              {{ 'interventionTranslation.conditionQuestion' | translate }}
                            </option>
                            <option key="lesson" :value="false">
                              {{ 'interventionTranslation.conditionLessonFinished' | translate }}
                            </option>
                          </select>

                          <div v-if="condition.type == true">
                            <!-- condition check mode not used, elements are not avalilable -> use saved text -->
                            <div
                              v-if="
                                !lessonElementsLabels
                                  || Object.keys(lessonElementsLabels).length === 0
                                  || !condition.questionnaire_id
                                  || !(condition.questionnaire_id in lessonElementsLabels)
                                  || condition.ifBlock == null
                              "
                            >
                              {{ (condition.questionnaire_id && condition.text) ? condition.text : $t("interventionTranslation.noConditionSet") }}
                            </div>
                            <!-- condition check mode used, elements are avalilable -> show actual details -->
                            <div
                              v-else
                              :class="{
                                errorMessage: treeToString(condition.ifBlock, lessonElementsLabels[condition.questionnaire_id].labelList, false).includes('...')
                              }"
                            >
                              {{ $t("interventionTranslation.conditionLesson") }}
                              {{ treeToString(condition.ifBlock, lessonElementsLabels[condition.questionnaire_id].labelList, false) }}
                            </div>
                            <br>
                            <br>
                            <button type="button" :disabled="!isOwner" @click="openConditionEditor(lesson.id, conditionIndex)">
                              {{getConditionButtonText(lesson.id, conditionIndex)}}
                            </button>
                          </div>
                        </section>
                      </section>
                    </section>

                    <section v-if="isOwner" class="col secAnswerLeft">
                      <i
                        class="fa fa-trash"
                        v-tooltip="$t('interventionTranslation.removeCondition')"
                        @click="removeCondition(lesson.id, conditionIndex)"
                      ></i>
                    </section>
                  </section>
                </div>
                <div class="addConditionButton">
                  <button type="button" :disabled="!isOwner" @click="addCondition(lesson.id)">
                    {{ 'interventionTranslation.addCondition' | translate }}
                  </button>
                </div>
              </section>

              <section class="wrapper">
                <section class="col sec50Middle">
                  <label :class="{ errorMessage: errorIndexes[lesson.id]?.feedback }">
                    {{ 'interventionTranslation.feedback' | translate }} *
                  </label>
                </section>
                <section class="col sec50Middle">
                  <button type="button" v-if="lessonIndex === useForAllButtonsIndex && interventionType === 'accompanied'" :id="'buttonSetFeedbackAll' + lesson.id"
                      :disabled="!isOwner" @click="setFeedbackForAll(lesson.id)">
                    {{ 'interventionTranslation.setUnlocktypeForAll' | translate }}
                  </button>
                </section>
              </section>
              <select v-if="interventionType === 'accompanied'" :value="getConfigAttributeOfLesson(lesson.id, 'feedback_required')" :id="'inputFeedbackRequired' + lesson.id" :disabled="!isOwner"
                  @change="setConfigAttributeOfLesson(lesson.id, $event.target.value, 'feedback_required', -1)">
                <option value="" :id="'inputFeedbackRequired' + lesson.id + 'None'" disabled>
                  {{ 'generalTranslation.select' | translate }}
                </option>
                <option :value="true" :id="'inputFeedbackRequired' + lesson.id + 'True'" :key="'feedbackTrue' + showIncluded">
                  {{ 'interventionTranslation.feedbackRequired' | translate }}
                </option>
                <option :value="false" :id="'inputFeedbackRequired' + lesson.id + 'False'" :key="'feedbackFalse' + showIncluded">
                  {{ 'interventionTranslation.feedbackNotRequired' | translate }}
                </option>
              </select>
              <p v-else :id="'feedbackNotGuidedText' + lesson.id">
                {{ 'interventionTranslation.feedbackNotRequired' | translate }}
              </p>
            </section>
            <section v-if="getConfigAttributeOfLesson(lesson.id, 'unlock_type') != 'conditional'" class="col sec50">
              <label>
                {{ 'interventionTranslation.unlockDiaries' | translate }}
              </label>
              <br>
              <ul v-if="lessonsDiaries.length > 0 && lessonsDiaries.filter(diary => diary.diaryId).length > 0" :id="'diariesLesson' + lesson.id">
                <li v-for="(diary, diaryIndex) in unlockDiaryList[lesson.id]" :id="'diariesLesson' + lesson.id + '-' + diaryIndex" :key="diaryIndex"> <!-- todo key -->
                  {{getNameOfDiary(diary)}}
                  <i
                    class="fa fa-trash"
                    v-if="isOwner"
                    :id="'buttonRemoveDiaryLesson' + lesson.id + '-' + diaryIndex"
                    v-tooltip="$t('lessonTranslation.removeElement')"
                    @click=" $emit('change-config', { attribute: 'unlock-diary-delete', lessonId: lesson.id, diaryIndex: diaryIndex})"
                  ></i>
                </li>
              </ul>
              <section v-if="unlockDiaryList[lesson.id] && unlockDiaryList[lesson.id].length === 0" :id="'diariesLesson' + lesson.id + 'None'">
                {{ 'interventionTranslation.unlockDiariesEmpty' | translate }}
              </section>
              <br>
              <label>
                {{ 'interventionTranslation.unlockDiariesAdd' | translate }}
              </label>
              <select v-if="getSelectableDiaries(lesson.id).length > 0" v-model="selectedDiariesList[lesson.id]" :id="'inputAddDiaryLesson' + lesson.id"
                  class="addLanguageSelect buttonForm" :disabled="!isOwner">
                <option value="" :id="'inputAddDiaryLesson' + lesson.id + 'None'" disabled selected>
                  {{ 'interventionTranslation.chooseDiary' | translate }}
                </option>
                <option v-for="diary in getSelectableDiaries(lesson.id)" :value="Number(diary.diaryId)" :id="'inputAddDiaryLesson' + lesson.id + '-' + diary.diaryId" :key="diary.diaryId">
                  {{diary.name}}
                </option>
              </select>
              <p v-else :id="'addDiaryLesson' + lesson.id + 'None'">
                {{noDiariesText(lesson.id)}}
              </p>
              <button type="button" v-if="getSelectableDiaries(lesson.id).length > 0" :id="'buttonAddDiaryLesson' + lesson.id" :disabled="!isOwner || selectedDiariesList[lesson.id] === ''"
                  class="buttonMiddle2" @click="updateUnlockDiary(lesson.id)">
                {{ 'interventionTranslation.add' | translate }}
              </button>
            </section>
          </section>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import questionConditionHelper from '../mixins/questionConditionHelper';

export default {
  name: 'InterventionEditorConfigElement', //todo format

  mixins: [questionConditionHelper],

  props: {
    showIncluded: {
      required: true,
      type: Boolean,
    },

    configJSON: {
      required: true,
      type: Array,
    },

    lessonsDiaries: {
      required: true,
      type: Array,
    },

    customOrder: {
      required: true,
      type: Array,
    },

    unlockDiaryList: {
      required: true,
      type: Object,
    },

    daysOrWeeks: {
      required: true,
      type: Array,
    },

    interventionType: {
      required: true,
      type: String,
    },

    errorIndexes: {
      required: true,
      type: Array,
    },

    lessonElementsLabels: {
      required: true,
      type: Object,
    },
  },

  data: function(){
    return{
      conditionalAfterPrevious: [],
      selectedDiariesList: {},
    }
  },

  computed: {
    ...mapGetters([
      'getMyRoleForStudy',
      'getNotificationText',
    ]),

    isOwner: function(){
      return (this.getMyRoleForStudy === "Owner")
    },

    unlockTypeList: function(){
      var list = [
        {
          value: "always",
          text: Vue.i18n.translate('interventionTranslation.always')
        },
        {
          value: "manually",
          text: Vue.i18n.translate('interventionTranslation.manually')
        },
        {
          value: "after_previous",
          text: Vue.i18n.translate('interventionTranslation.afterPrevious')
        },
        {
          value: "at_date",
          text: Vue.i18n.translate('interventionTranslation.atDate')
        },
        {
          value: "conditional",
          text: Vue.i18n.translate('interventionTranslation.condition')
        },
      ];
      if(!this.$enabledFeatures.includes('jitai')){
        list.splice(4,1);
      }
      return list
    },

    conditionalAfterPreviousText: function(){
      return Vue.i18n.translate('interventionTranslation.conditionalAfterPrevious')
    },

    headlineText: function(){
      if(this.showIncluded){
        return Vue.i18n.translate('interventionTranslation.inCustomOrder')
      }else{
        return Vue.i18n.translate('interventionTranslation.notInCustomOrder')
      }
    },

    useForAllButtonsIndex: function(){
      return (this.customOrder.length > 0) ? this.lessonsDiaries.findIndex(elem => this.customOrder.includes(Number(elem.id))) : 0
    },

    allSelectedDiaries: function(){
      var diaries = [];
      for(var diary in this.unlockDiaryList){
        for(var entry in this.unlockDiaryList[diary]){
          if(!diaries.includes(Number(this.unlockDiaryList[diary][entry]))){
            diaries.push(Number(this.unlockDiaryList[diary][entry]))
          }
        }
      }
      return diaries
    },

    inCustomOrderHasLessons: function(){
      for(var lesson in this.lessonsDiaries){
        if(!this.lessonsDiaries[lesson].diaryId){
          var lessonIdIncluded = this.customOrder.includes(Number(this.lessonsDiaries[lesson].id)) || this.customOrder.includes(this.lessonsDiaries[lesson].id);
          if(this.showIncluded && lessonIdIncluded || !this.showIncluded && !lessonIdIncluded){
            return true
          }
        }
      }
      return false
    },

    idForComp: function(){
      return this.showIncluded ? "Included" : "NotIncluded"
    },
  },

  created(){
    var selectedDiaries = {};
    for(var l in this.lessonsDiaries){
      if(!this.lessonsDiaries[l].diaryId){
        selectedDiaries[this.lessonsDiaries[l].id] = "";
      }
    }
    this.selectedDiariesList = selectedDiaries;

    this.getConditionalAfterPrevious();
  },

  watch: {
    configJSON: {
      handler: function(){
        this.getConditionalAfterPrevious();
      },
      deep: true
    },
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT'
    ]),

    updateUnlockDiary: function(lessonId){
      if(this.selectedDiariesList[lessonId] != ""){
        var newList = JSON.parse(JSON.stringify(this.unlockDiaryList[lessonId]));
        newList.push(this.selectedDiariesList[lessonId]);
        this.$emit('change-config', { attribute: 'unlock-diary-set', lessonId: lessonId, value: newList });
        this.selectedDiariesList[lessonId] = "";
      }
    },

    getConfigAttributeOfLesson: function(lessonId, attributeName){
      var index = this.configJSON.findIndex(elem => Number(elem.id) === Number(lessonId));
      if(index != -1){
        if(attributeName != "conditions" && attributeName != "bonus_lesson"){
          return this.configJSON[index][attributeName]
        }else if(attributeName === "bonus_lesson"){
          return (this.configJSON[index].bonus_lesson === undefined) ? true : this.configJSON[index].bonus_lesson
        }else{
          return (this.configJSON[index].condition) ? this.configJSON[index].condition : ""
        }
      }else{
        return ""
      }
    },

    setConfigAttributeOfLesson: function(lessonId, value, attributeName, conditionIndex){
      var index = this.configJSON.findIndex(elem => Number(elem.id) === Number(lessonId));
      if(index != -1){
        var val = value;
        if(attributeName === "feedback_required" || attributeName === "bonus_lesson"){
          val = (value === "true" || value === true);
        }
        this.$emit('change-config', { attribute: 'config-attribute', index: index, attributeName: attributeName, value: val, conditionIndex: conditionIndex });
      }
    },

    addCondition: function(lessonId){
      var conditionsForLesson = this.getConfigAttributeOfLesson(lessonId, 'conditions').length;
      if(this.getSelectableLessonsDiaries(lessonId, conditionsForLesson).length > 0 && conditionsForLesson < this.getSelectableLessonsDiaries(lessonId, -1).length){
        this.setConfigAttributeOfLesson(lessonId, '', 'addCondition', 0);
      }else{
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.addConditionError')});
      }
    },

    removeCondition: function(lessonId, conditionIndex){
      if(this.getConfigAttributeOfLesson(lessonId, 'conditions').length > 1){
        this.setConfigAttributeOfLesson(lessonId, '', 'removeCondition', conditionIndex)
      }else{
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.removeConditionError')});
      }
    },

    getNameOfDiary: function(diary){
      var index = this.lessonsDiaries.findIndex(elem => elem.diaryId && Number(elem.diaryId) === Number(diary));
      return (index != -1) ? this.lessonsDiaries[index].name : ""
    },

    getDaysOrWeeksOfLesson: function(lessonId){
      var index = this.configJSON.findIndex(elem => Number(elem.id) === Number(lessonId));
      return (index != -1) ? this.daysOrWeeks[index] : ""
    },

    setDaysOrWeeksOfLesson: function(lessonId, value){
      var index = this.configJSON.findIndex(elem => Number(elem.id) === Number(lessonId));
      if(index != -1){
        this.$emit('change-config', { attribute: 'days-or-weeks', index: index, value: value });
      }
    },

    getSelectableLessonsDiaries: function(id, conditionIndex){
      //diaryId === null -> lesson
      //diaryId != null -> diary -> has to be in unlockDiaries of a lesson or the intervention
      var list = this.lessonsDiaries.filter(diary => !diary.diaryId || this.allSelectedDiaries.includes(Number(diary.diaryId)))
        .filter(lessonDiary => id != lessonDiary.id)
        .filter(lessonDiary => id != Number(lessonDiary.id))

      //conditionIndex -1 returns all selectable lessons/diraies for this lesson
      //conditionIndex 0 .. n is filtered for specific condition index
      if(conditionIndex > -1){
        var index = this.configJSON.findIndex(elem => Number(elem.id) === Number(id));
        if(index != -1 && this.configJSON[index].unlock_type === "conditional"){
          var condition = this.configJSON[index].condition;
          for(var c in this.configJSON[index].condition){
            if(c != conditionIndex){
              list = list.filter(lessonDiary => condition[c].questionnaire_id != lessonDiary.id)
            }
          }
        }
      }

      return list
    },

    getSelectableDiaries: function(lessonId){
      return this.lessonsDiaries.filter(diary => diary.diaryId && this.unlockDiaryList[lessonId] && !this.unlockDiaryList[lessonId].includes(Number(diary.diaryId)))
        .filter(diary => diary.diaryId && this.unlockDiaryList[lessonId] && !this.unlockDiaryList[lessonId].includes(diary.diaryId))
    },

    setUnlockForAll: function(lessonId){
      this.$emit('change-config', { attribute: 'unlock-type-all', unlockType: this.getConfigAttributeOfLesson(lessonId, 'unlock_type'),
        days: this.getConfigAttributeOfLesson(lessonId, 'unlock_days_after_start'), daysOrWeeks: this.getDaysOrWeeksOfLesson(lessonId) });
    },

    setFeedbackForAll: function(lessonId){
      this.$emit('change-config', { attribute: 'feedback-all', value: this.getConfigAttributeOfLesson(lessonId, 'feedback_required') });
    },

    openConditionEditor: function(lessonId, conditionIndex){
      var conditions = this.getConfigAttributeOfLesson(lessonId, 'conditions');
      if(conditions[conditionIndex].questionnaire_id){
        this.$emit('open-condition-editor', { open: true, lessonId: lessonId, conditionId: conditions[conditionIndex].questionnaire_id,
          conditionJSON: conditions[conditionIndex].ifBlock, conditionIndex: conditionIndex});
      }else{
        this.$emit('open-condition-editor', { open: false });
      }
    },

    getConditionButtonText: function(lessonId, conditionIndex){
      var conditionsOfLesson = this.getConfigAttributeOfLesson(lessonId, 'conditions');
      var condition = conditionsOfLesson[conditionIndex].ifBlock;
      if(condition === null || condition === ""){
        return Vue.i18n.translate('elementsBlockTranslation.defineCondition')
      }else{
        return Vue.i18n.translate('elementsBlockTranslation.changeCondition')
      }
    },

    getConditionalAfterPrevious: function(){
      if(this.$enabledFeatures.includes('jitai')){
        var list = [];
        var isConditionalAfterPrevious = false;
        for(var config in this.configJSON){
          if(this.configJSON[config].unlock_type === "conditional"){
            isConditionalAfterPrevious = true;
          }else if(this.configJSON[config].unlock_type === "after_previous" && isConditionalAfterPrevious && !list.includes(Number(this.configJSON[config].id))){
            list.push(Number(this.configJSON[config].id));
          }
        }
        this.conditionalAfterPrevious = list;
      }
    },

    noDiariesText: function(lessonId){
      return (!this.unlockDiaryList[lessonId] || this.unlockDiaryList[lessonId].length === 0) ? Vue.i18n.translate('interventionTranslation.noDiariesInStudy') : Vue.i18n.translate('interventionTranslation.noMoreDiariesInStudy')
    }
  }
}
</script>
